import { render, staticRenderFns } from "./HorizontalRule.vue?vue&type=template&id=b2625ffe&scoped=true"
import script from "./HorizontalRule.vue?vue&type=script&lang=js"
export * from "./HorizontalRule.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2625ffe",
  null
  
)

export default component.exports